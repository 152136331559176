import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
// import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import Stack from '@mui/material/Stack';
import { height, minWidth, padding } from "@mui/system";
// import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Unstable_Grid2";
import myImage from "../Asset11.svg";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minWidth: "327px",
  maxWidth: "800px",
  height: "341px",
}));

export default function ResponsiveGrid({ whyusRef }) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#154E69",
        alignItems: "center",
        justifyContent: "center",
        display: "flex", // Added display: 'flex' to align children vertically
        flexDirection: "column",
        minheight: "878px",
        paddingBottom: "20px",
        width: "100%",
      }}
      id="whyus"
      ref={whyusRef}
    >
      <Box
        sx={{
          "@media (min-width:600px)": {
            paddingTop: "120px",
            paddingBottom: "20px",
          },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          gutterBottom
          color="#ffffff"
          paddingTop="50px"
        >
          Why Us
        </Typography>
      </Box>

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          paddingLeft: "20px",
          paddingRight: "20px",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            minwidth: 128,
            minheight: 128,
            backgroundColor: "transparent",
          },
          "@media (min-width:600px)": {
            paddingLeft: "5%",
            paddingRight: "5%",
          },
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            minwidth: "50%",
            textAlign: "Left",
            "@media (min-width:600px)": {
              paddingRight: "50px",
              textAlign: "Left",
            },
          }}
        >
          <Typography
          sx={{ textAlign: "justify" }}
            color="#ffffff"
            maxWidth="800px"
            minheight="261px"
            fontFamily="Poppins, sans-serif"
            fontsize="24px"
          >
            In India, even some of our best academic institutions are focused on developing technical skills only. These skills help you to get a job but often individuals find it hard to find and sustain in the right career path. For a thriving economy, the emphasis on purpose, career clarity, personal development, and entrepreneurial leadership remains conspicuously absent. Undergoing a multitude of experiences ourselves in the last decade and witnessing our peers struggle to find the right career track and stay satisfied with their careers, we were determined to help students to get from where they are to where they want to be breaking all barriers in the system.
          </Typography>
        </Box>

        <Box
          sx={{
            alignItems: "center",
            minWidth: "50%",
            margin: "auto",
            "@media screen and (max-width: 1400px)": {
              maxWidth: "40%",
            },
            "@media screen and (max-width: 1000px)": {
              maxWidth: "unset",
            },
          }}
        >
          <img
            style={{
              maxWidth: "100%",
              height: "auto",
              "@media screen and (min-width:800px)": {
                maxWidth: "600px",
              },
              // "@media screen and (min-width:800px)": {
              //   maxWidth: "600px",
              // },
            }}
            src={myImage}
          ></img>
        </Box>
      </Box>

      <Typography
        variant="h4"
        gutterBottom
        color="#ffffff"
        paddingTop="34px"
        paddingLeft="20px"
        paddingRight="20px"
        textAlign="center"
        paddingBottom="120px"
      >
        Our Goal Is To Make That Path Easier For The Next Generation.
      </Typography>
      {/* <Button
        variant="outlined"
        color="primary"
        endIcon={<ArrowForwardIcon />}
        sx={{
          borderRadius: 10,
          color: "white",
          borderColor: "white", // Set the outline color to white
          "&:hover": {
            borderColor: "white",
          },
        }}
      >
        Get More
      </Button> */}
    </Box>
  );
}
