import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function ThankYou() {
  return (
    <Box
      sx={{
        backgroundColor: "#CCC",
        py: "140px",
        width: "100vw",
        textAlign: "center",
      }}
      id="contact-us"
    >
      <Typography variant="h4" gutterBottom color="#000" textAlign="center">
        Thank You for the feedback
      </Typography>
    </Box>
  );
}
