import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
// import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import Stack from '@mui/material/Stack';
import { height, minWidth, padding } from "@mui/system";
// import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Unstable_Grid2";
import myImage from "../mission.svg";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(2),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
//   minWidth: '327px',
//   maxWidth: '800px',
//   height: '341px'
// }));

export default function Mission({ ourmissionRef }) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#F9F9FF",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        // minheight: '878px',
        paddingBottom: "20px",
      }}
      id="our-mission"
      ref={ourmissionRef}
    >
      <Box
        sx={{
          textAlign: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
          "@media (min-width:600px)": {
            paddingTop: "50px",
            paddingLeft: "0",
            paddingRight: "0",
          },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          gutterBottom
          color="#0d0d0d"
          paddingTop="50px"
        >
          Our Mission
        </Typography>
        <Typography
          fontSize="24px"
          fontFamily="Poppins, sans-serif"
          color="#707070"
          paddingTop="20px"
          maxWidth="800px"
          marginBottom="20px"
          sx={{
            textAlign: "justify"
            , "@media (max-width:950px)": {
              width: "80%",
              margin: "auto",
              marginBottom: "20px",
            },
          }}
        >
          We aim to bridge the access gap and democratize opportunities, network and resources to the young minds of India by helping them to nurture their true potential and develop the skills needed to transition to their first careers.

        </Typography>
      </Box>

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          paddingLeft: "20px",
          paddingRight: "20px",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            minwidth: 128,
            minheight: 128,
            backgroundColor: "transparent",
          },
          "@media (min-width:600px)": {
            paddingLeft: "100px",
            paddingRight: "100px",
          },
        }}
      >
        {/* 
        <Box sx={{ alignItems: 'center', display: 'flex', flex: 1,minwidth:'50%',textAlign:'Left','@media (min-width:600px)': {
                paddingRight: '50px',
                textAlign:'Left'
              } }}>
          <Typography color="#ffffff" maxWidth="800px" minheight="261px" fontFamily="Inter" fontsize="24px"
           >
            We Are A Bunch Of Working Professionals From Varied Backgrounds. We Have Having Diverse Experiences Across Multiple Industry Sectors Such As Finance, EdTech, Non-Profit, Legal, Design, Consulting And Many More. Hailing From Multiple Institutions Including The Iits, We Have First-Hand Experience About This Problem Statement. We Had To Undergo A Bouquet Of Experiences To Carve Out Our Niche And Find The Right Path. We Have Witnessed A Lot Of Our Colleagues, Friends And People In Our Network Struggle To Find The Right Career Track And Stay Satisfied With Their Jobs
          </Typography>
        </Box> */}

        <Box sx={{ alignItems: "center", minWidth: "50%" }}>
          <img
            style={{
              maxWidth: "100%",
              height: "auto",
              "@media screen and (min-width:800px)": {
                maxWidth: "600px",
              },
            }}
            src={myImage}
          ></img>
        </Box>
      </Box>
    </Box>
  );
}
