import { styled } from "@mui/system";
import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useEffect from "react";
import myImage from "../logo.svg";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "white",
  boxShadow: "none",
  position: "sticky",
}));

const drawerWidth = 240;
const navItems = [
  { label: "What do we do", path: "/whatdowedo" },
  { label: "Why Us", path: "/whyus" },
  { label: "What are we solving", path: "/whatarewesolving" },
  { label: "Our Mission", path: "/our-mission" },
  { label: "About Us", path: "/about" },
  { label: "Contact Us", path: "/contact-us" },
];

function DrawerAppBar(props) {
  const location = useLocation();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate(); // Get the navigate function from useNavigate

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleNavigation = (path) => {
    navigate(path); // Programmatically navigate to the specified path
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box sx={{ alignItems: "center", justifyContent: "center" }}>
        <img
          style={{ width: "205px", height: "68px", paddingLeft: "40px" }}
          src={myImage}
        />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton
              onClick={() => {
                if (item.path === "/contact-us")
                  return props.scrollToForm(props.formRef);
                if (item.path === "/whyus")
                  return props.scrollToForm(props.whyusRef);
                if (item.path === "/about")
                  return props.scrollToForm(props.aboutusRef);
                if (item.path === "/our-mission")
                  return props.scrollToForm(props.ourmissionRef);
                if (item.path === "/whatdowedo")
                  return props.scrollToForm(props.whatwedoRef);
                if (item.path === "/whatarewesolving")
                  return props.scrollToForm(props.whatarewesolvingRef);
              }}
              sx={{ textAlign: "center" }}
            >
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      {/* <Button
        variant="outlined"
        color="inherit"
        sx={{ color: "black", marginTop: "10px" }}
      >
        Donate
      </Button> */}
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <StyledAppBar component="nav">
        <Toolbar>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              sx={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              color: "#227DA2",
              display: "flex",
              justifyContent: { xs: "flex-center", sm: "flex-start" },
            }}
          >
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <Box sx={{ alignItems: "center", justifyContent: "center" }}>
                <img
                  style={{
                    width: "205px",
                    height: "68px",
                    paddingLeft: "40px",
                  }}
                  src={myImage}
                ></img>
              </Box>
            </Link>
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button
                key={item.label}
                onClick={() => {
                  if (item.path === "/contact-us")
                    return props.scrollToForm(props.formRef);
                  if (item.path === "/whyus")
                    return props.scrollToForm(props.whyusRef);
                  if (item.path === "/about")
                    return props.scrollToForm(props.aboutusRef);
                  if (item.path === "/our-mission")
                    return props.scrollToForm(props.ourmissionRef);
                  if (item.path === "/whatdowedo")
                    return props.scrollToForm(props.whatwedoRef);
                  if (item.path === "/whatarewesolving")
                    return props.scrollToForm(props.whatarewesolvingRef);
                }}
                sx={{ color: "#227DA2" }}
              >
                {item.label}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </StyledAppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default DrawerAppBar;
