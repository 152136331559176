import React, { useRef } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { alpha, styled } from "@mui/material/styles";
import PinterestIcon from "@mui/icons-material/Pinterest";
import InputBase from "@mui/material/InputBase";
import { TextField, Button, Container, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useState } from "react";

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 12px;
    fieldset {
      border-color: #fff;
      color: red;
      background: #fff;
      border-radius: 12px;
    }
    &:hover fieldset {
      border-color: #fff;
    }
    &.Mui-focused fieldset {
      border-color: #fff;
    }
  }

  & .MuiFormLabel-root {
    color: #000;
    z-index: 1000000001;
    &.Mui-focused {
      color: #000;
    }
  }

  & .MuiInputBase-input {
    color: #2a2a2a;
    font-size: 16px;
    background-color: #fff;
    border-radius: 12px;
    z-index: 100000000;
  }
`;

const Form = (props) => {
  // Validate form
  const { register, handleSubmit } = useForm();
  const [fNameError, setFNameError] = useState();
  const [phoneNumberError, setphoneNumberError] = useState();
  const [emailError, setEmailError] = useState();
  const [commentError, setCommentlError] = useState();

  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();
  const phoneNumberInputRef = useRef();
  const emailAddressInputRef = useRef();
  const commentRef = useRef();

  function submitHandler(event) {
    event.preventDefault();
    const enteredFirstName =
      firstNameInputRef.current.querySelector("input").value;
    const enteredLastName =
      lastNameInputRef.current.querySelector("input").value;
    const enteredphoneNumber =
      phoneNumberInputRef.current.querySelector("input").value;
    const enteredEmailAddress =
      emailAddressInputRef.current.querySelector("input").value;

    // work on getting the comment
    const enteredComment = commentRef.current.querySelector("textarea").value;

    // checking the input
    let formDirty = false;
    if (!enteredFirstName || !enteredFirstName.length) {
      setFNameError("First Name is required");
      formDirty = true;
    } else {
      setFNameError("");
    }
    if (!enteredphoneNumber || !enteredphoneNumber.length) {
      setphoneNumberError("Phone Number is required");
      formDirty = true;
    } else {
      setFNameError("");
    }
    if (!enteredEmailAddress || !enteredEmailAddress.length) {
      setEmailError("Email is required");
      formDirty = true;
    } else {
      setFNameError("");
    }
    if (!enteredComment || !enteredComment.length) {
      setCommentlError("Name is required");
      formDirty = true;
    } else {
      setFNameError("");
    }
    if (!formDirty) {
      // const userInput = {
      //   FirstName: enteredFirstName,
      //   LastName: enteredLastName,
      //   PhoneNumber: enteredphoneNumber,
      //   EmailAddress: enteredEmailAddress,
      //   Comment: enteredComment,
      // };
      const userInput = [
        enteredFirstName,
        enteredLastName,
        enteredphoneNumber,
        enteredEmailAddress,
        enteredComment,
      ];
      props.submitHandler(userInput);
      return true;
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: "#CCC",
        py: "140px",
        "@media (max-width: 600px)": { py: "50px" },
      }}
      id="contact-us"
      ref={props.formRef}
    >
      <Typography
        variant="h4"
        fontWeight="bold"
        gutterBottom
        color="#000"
        textAlign="center"
      >
        Get in Touch
      </Typography>
      <Box
        component="form"
        sx={{
          maxWidth: "1348px",
          minWidth: "200px",
          margin: "auto",
          px: "5%",
        }}
        noValidate
        onSubmit={submitHandler}
      >
        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "row" }}
          sx={{ my: 5 }}
        >
          <StyledTextField
            label="First Name"
            id="custom-css-outlined-input"
            type="text"
            variant="outlined"
            // onChange=""
            // value={firstName}
            error={fNameError && fNameError.length ? true : false}
            fullWidth
            required
            ref={firstNameInputRef}
            name="FirstName"
          />
          <StyledTextField
            type="text"
            variant="outlined"
            label="Last Name"
            // onChange=""
            // value={lastName}
            fullWidth
            ref={lastNameInputRef}
            name="LastName"
          />
        </Stack>
        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "row" }}
          sx={{ my: 5, "@media (max-width: 600px)": { direction: "column" } }}
        >
          <StyledTextField
            type="tel"
            variant="outlined"
            label="Phone Number"
            // onChange=""
            // value="Usman"
            error={phoneNumberError && phoneNumberError.length ? true : false}
            fullWidth
            required
            ref={phoneNumberInputRef}
            name="PhoneNumber"
          />
          <StyledTextField
            type="email"
            variant="outlined"
            label="Email Address"
            // onChange=""
            // value="Usman"
            error={emailError && emailError ? true : false}
            fullWidth
            required
            ref={emailAddressInputRef}
            name="EmailAddress"
          />
        </Stack>
        <StyledTextField
          type="text"
          id="comment"
          label="Send us a message"
          fullWidth
          required
          multiline
          error={commentError && commentError.length ? true : false}
          rows={4}
          ref={commentRef}
          name="Comment"
        ></StyledTextField>
        <Box
          sx={{
            padding: "50px",
            textAlign: "center",
          }}
        >
          <Button
            variant="outlined"
            type="submit"
            sx={{
              color: "#333697",
              borderColor: "#333697", // Set the outline color to white
              borderRadius: 10,
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Form;
