import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TeamMember from "./teams";
import arrowRight from "../teamsImage/arrowRight.svg";
import arrowLeft from "../teamsImage/arrowLeft.svg";
import debanjan from "../teamsImage/debanjan.svg";
import susmit from "../teamsImage/susmit.svg";
import { useState, useEffect } from "react";
import myImage from "../teamsImage/aboutbackground.svg";

const AboutUs = ({ aboutusRef }) => {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const teamMembers = [
    {
      id: 0,
      teamsName: "Debanjan",
      image: debanjan,
      imageWidth: "500px",
      href: "https://www.linkedin.com/in/debanjan2",
    },
    {
      id: 1,
      teamsName: "Susmit",
      image: susmit,
      imageWidth: "500px",
      href: "https://www.linkedin.com/in/susmit-roy-2034a6101",
    },
    // {
    //   id: 2,
    //   teamsName: "Debanjan",
    //   image: debanjan,
    //   imageWidth: "500px",
    //   href: "https://github.com/Unique-Usman/llvm-project/tree/main",
    // },
    // {
    //   id: 3,
    //   teamsName: "Debanjan",
    //   image: debanjan,
    //   imageWidth: "500px",
    //   href: "https://github.com/Unique-Usman/llvm-project/tree/main",
    // },
  ];
  return (
    <Box
      sx={{ backgroundColor: "#F9F9FF", paddingTop: "2rem" }}
      id="about"
      ref={aboutusRef}
    >
      <Box>
        <Typography variant="h4" fontWeight="bold" textAlign="center">
          About Us
        </Typography>
        <Typography
          textAlign="center"
          margin="auto"
          fontFamily="Poppins, sans-serif"
          fontsize="24px"
        >
          Unique, personalized, customized and optimized. We do things
          differently.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          paddingTop: "64px",
          px: "5%",
          // backgroundImage: `url(${myImage})`,
          // backgroundRepeat: "no-repeat",
          // backgroundPosition: "center",
          // backgroundSize: "contain",
        }}
      >
        {teamMembers.map((teamMember, i) => {
          console.log(window.innerWidth);
          return (
            <TeamMember
              width={teamMember.imageWidth}
              name={teamMember.teamsName}
              top={windowSize[0] > 500 ? "75%" : "70%"}
              left={i % 2 === 0 ? "60%" : "10%"}
              image={i % 2 === 0 ? arrowRight : arrowLeft}
              teamImage={teamMember.image}
              href={teamMember.href}
            ></TeamMember>
          );
        })}
      </Box>
    </Box>
  );
};

export default AboutUs;
