import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import image1 from "../whatwedo1.svg";
import image2 from "../whatwedo2.svg";
import image3 from "../whatwedo3.svg";

export default function SimplePaper({ whatwedoRef }) {
  return (
    <div id="about">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "120px",
          // border: '1px solid #ddd',
        }}
        id="whatdowedo"
        ref={whatwedoRef}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "30px",
            // border: '1px solid #ddd',
            "@media (min-width:600px)": {
              paddingTop: "120px",
            },
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            gutterBottom
            textAlign="center"
          >
            What Do We Do?
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            "& > :not(style)": {
              m: 1,
              //   minwidth: '428 px',
              //   minHeight: '513 px',
              backgroundColor: "#F9F9FF",
            },
          }}
        >
          <Paper elevation={4} sx={{ borderRadius: "20px" }}>
            <Card
              sx={{
                maxWidth: "400px",
                minHeight: "480px",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "30px",
                borderRadius: "20px",
              }}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  minHeight="235px"
                  minwidth="250px"
                  src={image1}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    paddingTop="20px"
                    fontFamily="Poppins, sans-serif"
                    fontsize="24px"
                  >
                    We take students early into the system and prepare them
                    to be ready for the career track of their choice.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Paper>
          <Paper elevation={4} sx={{ borderRadius: "20px" }}>
            <Card
              sx={{
                maxWidth: "400px",
                minHeight: "480px",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "30px",
                borderRadius: "20px",
              }}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  minHeight="270px"
                  minwidth="224px"
                  src={image2}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    paddingTop="20px"
                    fontFamily="Poppins, sans-serif"
                    fontsize="24px"
                  >
                    We follow an experiential learning model where students
                    follow a 5 step process: Do - Reflect - Learn - Do (Again) -
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Paper>
          <Paper elevation={4} sx={{ borderRadius: "20px" }}>
            <Card
              sx={{
                maxWidth: "400px",
                minHeight: "480px",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "30px",
                borderRadius: "20px",
              }}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  minHeight="236px"
                  minwidth="236px"
                  src={image3}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    paddingTop="20px"
                    fontFamily="Poppins, sans-serif"
                    fontsize="24px"
                  >
                    We bridge the gap through a series of activities including
                    guest lectures, industry workshops, peer mentorships
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Paper>
          {/* <Paper elevation={3}/>
      <Paper elevation={3} /> */}
        </Box>
      </Box>
    </div>
  );
}
