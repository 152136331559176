import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import myImage from "../background.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Solving from "./whatarewesolving";
import Mission from "./mission";
import BasicAccordion from "./faqs";
import Form from "./form";
import WhyUs from "./whyus";
import SimplePaper from "./whatdowedo";
import ThankYou from "./thankyou";
import AboutUs from "./about.js";
import { useEffect, useRef, useState } from "react";

const Body = (props) => {
  const [showForm, setShowForm] = useState(true);
  function addSubmitHandler(userInput) {
    fetch(
      "https://v1.nocodeapi.com/unique_usman/google_sheets/iIigOwKFQzsweQTk?tabId=Sheet1",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify([userInput]),
      }
    ).then(() => {
      setShowForm(false);
      setTimeout(() => (window.location.href = window.location.href), 2000);
    });
  }
  return (
    <div
      style={
        {
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "center",
          // justifyContent: "center",
          // height: "100vh",
          // textAlign: "center",
          // color: "white",
          // padding: "0 50px",
          // backgroundImage: `url(${myImage})`,
          // backgroundSize: "cover",
          // backgroundRepeat: "no-repeat",
          // backgroundPosition: "center",
        }
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
          color: "white",
          padding: "0 50px",
          backgroundImage: `url(${myImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Typography
          variant="h4"
          component="div"
          style={{
            marginBottom: "60px",
            width: "80%",
            "@media (min-width:600px)": {},
          }}
        >
          Building a community of the next generation of India-preneurs where
          passion and purpose meet potential
        </Typography>
        {/* <Button
          variant="outlined"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          sx={{
            color: "white",
            borderColor: "white",
            borderRadius: 10,
            // Set the outline color to white
            "&:hover": {
              borderColor: "white", // Set the outline color to white on hover
            },
          }}
        >
          Explore More
        </Button> */}
      </div>
      <SimplePaper whatwedoRef={props.whatwedoRef} />
      <WhyUs whyusRef={props.whyusRef} />
      <Solving whatarewesolvingRef={props.whatarewesolvingRef} />
      <Mission ourmissionRef={props.ourmissionRef} />
      <AboutUs aboutusRef={props.aboutusRef} />
      {/* <BasicAccordion faqRef={props.faqRef} /> */}
      {showForm && (
        <Form formRef={props.formRef} submitHandler={addSubmitHandler} />
      )}
      {!showForm && <ThankYou />}
    </div>
  );
};

export default Body;
