import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";

export default function TeamMember(props) {
  return (
    <Box
      sx={{
        height: `${props.height}`,
        width: `${props.width}`,
        mx: "2rem",
        my: "2rem",
        position: "relative",
      }}
    >
      <img
        style={{
          width: "100%",
          height: "100%",
        }}
        src={props.teamImage}
      ></img>
      <img
        style={{
          width: "100px",
          height: "100px",
          display: "block",
          position: "absolute",
          top: `${props.top}`,
          left: `${props.left}`,
        }}
        src={props.image}
      ></img>
      <Typography variant="h4" fontWeight="bold" textAlign="center">
        {props.name}
      </Typography>
      <IconButton
        href={props.href}
        sx={{
          display: "block",
          width: "fit-content",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <LinkedInIcon
          sx={{
            color: "#0072b1",
            fontSize: "56px",
            textAlign: "center",
            margin: "auto",
            display: "block",
          }}
        />
      </IconButton>
    </Box>
  );
}
