import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinterestIcon from "@mui/icons-material/Pinterest";
import myImage from "../logo.svg";
import { useNavigate, useLocation } from "react-router-dom";

import {
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Link,
  ListItemText,
  IconButton,
  Divider,
} from "@mui/material";

const footerDetails = [
  {
    head: "",
    content: ["What do we do", "Why Us", "What are we solving"],
  },
  {
    head: "",
    content: ["Our Mission", "About Us", "Reach Out To Us"],
  },
  {
    head: "Contact Us",
    content: [
      "+91 8017 4872 129",
      "Indianinstituteofikigai@gmail.com",
      // "Address",
    ],
  },
];

const socialMedia = [
  {
    icon: <FacebookSharpIcon sx={{ fontSize: 38 }} />,
    href: "https://twitter.com/usman_akinyemi",
  },
  {
    icon: <TwitterIcon sx={{ fontSize: 38 }} />,
    href: "https://twitter.com/usman_akinyemi",
  },
  {
    icon: <LinkedInIcon sx={{ fontSize: 38 }} />,
    href: "https://twitter.com/usman_akinyemi",
  },
  {
    icon: <PinterestIcon sx={{ fontSize: 38 }} />,
    href: "https://twitter.com/usman_akinyemi",
  },
];
const Footer = (props) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundColor: "#EBEBF8",
        display: "flex",
        justifyContent: "center",
        pt: "153px",
      }}
    >
      <Box sx={{ margin: "auto", width: "90%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: "fit-content",
              "@media  (max-width: 840px)": {
                order: 3,
                width: "50%",
              },
              "@media  (max-width: 520px)": {
                order: 3,
                width: "50%",
                // margin: "auto",
              },
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                color: "black",
                display: "flex",
                mb: "44px",
                textAlign: "center",
              }}
            >
              <Link
                href="/"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "block",
                  margin: "auto",
                }}
              >
                <img
                  style={{
                    width: "150px",
                    height: "50px",
                    margin: "auto",
                    textAlign: "center",
                    margin: "auto",
                  }}
                  src={myImage}
                ></img>
              </Link>
            </Typography>
            {/* <Box
              sx={{
                // display: "flex",
                // flexDirection: "row",
                // justify: "center",
                // alignItems: "center",
                // margin: "auto",
                // textAlign: "center",
                width: "100%",
                margin: "auto",
              }}
            >
              {socialMedia.map((socialMedium) => {
                return (
                  <IconButton
                    href={socialMedium.href}
                    sx={{
                      "@media (max-width: 520px)": {
                        display: "block",
                        width: "fit-content",
                        margin: "auto",
                      },
                    }}
                  >
                    {socialMedium.icon}
                  </IconButton>
                );
              })}
            </Box> */}
          </Box>
          {footerDetails.map((footerDetails, i) => {
            return (
              <Box
                sx={{
                  width: "fit-content",
                  "@media (max-width: 840px)": {
                    width: "50%",
                  },
                  "@media  (max-width: 520px)": {
                    fontSize: "14px",
                    // textAlign: "center",
                    // margin: "auto",
                  },
                }}
              >
                <List
                  subheader={
                    <ListSubheader
                      sx={{
                        backgroundColor: "#EBEBF8",
                        fontWeight: "bold",
                        color: "#000",
                        marginLeft: "16px",
                      }}
                    >
                      {footerDetails.head}
                    </ListSubheader>
                  }
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  {footerDetails.content.map((footerLink) => (
                    <ListItem
                      sx={{
                        color: "#8A8A8A;",
                        "@media  (max-width: 520px)": {
                          fontSize: "14px",
                          // textAlign: "center",
                          // margin: "auto",
                        },
                      }}
                    >
                      <ListItemButton>
                        <ListItemText
                          primary={footerLink}
                          onClick={() => {
                            if (footerLink === "About Us")
                              return props.scrollToForm(props.aboutusRef);
                            if (footerLink === "Why Us")
                              return props.scrollToForm(props.whyusRef);
                            if (footerLink === "What do we do")
                              return props.scrollToForm(props.whatwedoRef);
                            if (footerLink === "What are we solving")
                              return props.scrollToForm(
                                props.whatarewesolvingRef
                              );
                            if (footerLink === "Our Mission")
                              return props.scrollToForm(props.ourmissionRef);
                            if (footerLink === "Reach Out To Us")
                              return props.scrollToForm(props.formRef);
                          }}
                        ></ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: "5%",
            mt: "43px",
            mb: "63px",
            color: "#677294",
            fontFamily: "Poppins, sans-serif",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >
          <Typography sx={{ fontSize: "15px" }}>&copy; III 2023</Typography>
          <Typography sx={{ fontSize: "15px" }}>
            Terms of use | Privacy Policy
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
