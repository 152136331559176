import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
// import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import Stack from '@mui/material/Stack';
import { height, padding } from "@mui/system";
// import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Unstable_Grid2";
import myImage from "../Asset12.png";
import myImage2 from "../Asset2.svg";
import myImage3 from "../Asset3.svg";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minWidth: "327px",
  maxWidth: "800px",
  height: "341px",
}));

export default function Solving({ whatarewesolvingRef }) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#F9F9FF",
        alignItems: "center",
        justifyContent: "center",
        display: "flex", // Added display: 'flex' to align children vertically
        flexDirection: "column",
        minheight: "878px",
        paddingBottom: "20px",
      }}
      id={whatarewesolvingRef}
      ref={whatarewesolvingRef}
    >
      <Box
        sx={{
          paddingBottom: "50px",
          "@media (min-width:600px)": {
            paddingBottom: "150px",
          },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          gutterBottom
          color="What Are We Solving"
          paddingTop="140px"
          textAlign="center"
        >
          What Are We Solving
        </Typography>
      </Box>

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          // paddingLeft: "5%",
          // paddingRight: "5%",
          justifyContent: "space-between",
          mx: "4rem",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minheight: 128,
            backgroundColor: "transparent",
          },
          // "@media (min-width:600px) and (max-width: 950px)": {
          //   paddingLeft: "100px",
          //   paddingRight: "100px",
          // },
          "@media (min-width:600px) and (max-width: 950px)": {
            flexDirection: "column",
            alignItems: "center",
          },
          "@media (max-width:600px)": {
            flexDirection: "column-reverse",
          },
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            maxWidth: "500px",
            "@media (min-width:600px) and (max-width: 950px)": {
              maxWidth: "80%",
            },
          }}
        >
          <img
            style={{
              maxWidth: "100%",
              "@media (min-width:600px) and (max-width: 950px)": {
                maxWidth: "80%",
              },
              height: "auto",
              "@media screen and (min-width:800px)": {
                maxWidth: "100%",
              },
            }}
            src={myImage}
          ></img>
        </Box>

        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            // paddingLeft: "20px",
            // textAlign: "Left",
            "@media (min-width:600px)": {
              // paddingLeft: "50px",
              // textAlign: "Left",
            },
            "@media (min-width:600px) and (max-width: 950px)": {
              maxWidth: "80%",
            },
            "@media (max-width:600px)": {
              flexDirection: "column-reverse",
            },
          }}
        >
          <Typography
            sx={{ textAlign: "justify" }}
            color="#241B1B"
            maxWidth="800px"
            minheight="261px"
            fontFamily="Poppins, sans-serif"
            fontsize="24px"
          >
            97% of white-collar Indian workforce want a career change. They are suffering from dissatisfaction, lack of fulfillment and clarity, low productivity, burnout, discrimination and motivation issues. Our system often correlates success and identity with dignified traditional professions, high ranks or paychecks. Students are wired to run after degrees and certificates, and not skill sets. The future of work demands students to adapt to the problems that they want to solve and the portfolio of skillsets that they need to develop rather than focusing on a particular occupation only which might not exist in a few years from now.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          // paddingLeft: "5%",
          // paddingRight: "5%",
          justifyContent: "space-between",
          flexWrap: "wrap",
          mx: "4rem",
          "& > :not(style)": {
            m: 1,
            Width: "100%",
            minheight: 128,
            backgroundColor: "transparent",
          },
          "@media (min-width:600px)": {
            // paddingLeft: "100px",
            // paddingRight: "100px",
          },
          "@media (min-width:600px) and (max-width: 950px)": {
            flexDirection: "column-reverse",
            alignItems: "center",
          },
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flex: 1,

            // paddingLeft: "20px",
            // textAlign: "Left",
            "@media (min-width:600px)": {
              // paddingRight: "50px",
              // paddingLeft: "0px",
              // textAlign: "Left",
            },
            "@media (min-width:600px) and (max-width: 950px)": {
              maxWidth: "80%",
            },
          }}
        >
          <Typography
            sx={{ textAlign: "justify" }}
            color="#241B1B"
            maxWidth="800px"
            minheight="261px"
            fontFamily="Poppins, sans-serif"
            fontsize="24px"
          >
            In the era of rapidly evolving technology, we need to decouple jobs from identity. As an initiative, we want to encourage young minds to lean into their natural curiosities, imaginations and creativities, and lean away from the idea that ‘you are your job’. The jobs of today require a culmination of skill sets which no traditional degree prepares you for. For example, the role of a social media director of a fashion company didn’t exist 10 years back. This role works at the intersection of fashion industry knowledge, digital and data analytics skill sets, psychology and people skills, marketing skills and basic legal knowledge.


          </Typography>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            maxWidth: "500px",
            "@media (min-width:600px) and (max-width: 950px)": {
              maxWidth: "80%",
            },
          }}
        >
          <img
            style={{
              maxWidth: "100%",
              height: "auto",
              "@media screen and (min-width:800px)": {
                // maxWidth: "600px",
              },
            }}
            src={myImage2}
          ></img>
        </Box>
      </Box>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          // paddingLeft: "5%",
          // paddingRight: "5%",
          justifyContent: "space-between",
          flexWrap: "wrap",
          mx: "4rem",
          "& > :not(style)": {
            m: 1,
            Width: "100%",
            minheight: 128,
            backgroundColor: "transparent",
          },
          "@media (min-width:600px)": {
            // paddingLeft: "100px",
            // paddingRight: "100px",
          },
          "@media (max-width:600px)": {
            flexDirection: "column-reverse",
          },
          "@media (min-width:600px) and (max-width: 950px)": {
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            maxWidth: "500px",
            // width: "500px",
            "@media (min-width:600px) and (max-width: 950px)": {
              maxWidth: "80%",
            },
          }}
        >
          <img
            style={{
              maxWidth: "100%",
              height: "auto",
              "@media screen and (min-width:800px)": {
                // maxWidth: "100%",
              },
            }}
            src={myImage3}
          ></img>
        </Box>

        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            // paddingLeft: "20px",
            // textAlign: "Left",
            "@media (min-width:600px)": {
              // paddingLeft: "50px",
              // textAlign: "Left",
            },
            "@media (min-width:600px) and (max-width: 950px)": {
              maxWidth: "80%",
            },
          }}
        >
          <Typography
            sx={{ textAlign: "justify" }}
            color="#241B1B"
            maxWidth="800px"
            minheight="261px"
            fontFamily="Poppins, sans-serif"
            fontsize="24px"
          >
            We empower students to explore their purpose, discover their passion, weigh their strengths, become adaptable, explore skillsets and careers, and finally tread on to a career path where they can maximise their potential and thrive for the years to come.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
