import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/system";
import myImage from "./background.svg";
import Navbar from "./components/navbar";
import Body from "./components/body";
import AboutUs from "./components/about";
import WhyUs from "./components/whyus";
import SimplePaper from "./components/whatdowedo";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Solving from "./components/whatarewesolving";
import Mission from "./components/mission";
import BasicAccordion from "./components/faqs";
import Form from "./components/form";
import Footer from "./components/footer";
import ThankYou from "./components/thankyou";

// const StyledPage = styled('div')({
//   backgroundImage: `url(${myImage})`,
//   backgroundSize: 'cover',
//   backgroundRepeat: 'no-repeat',
//   backgroundPosition: 'center',
//   minHeight: '100vh', // Set the minimum height of the page
// });

function App() {
  const [showForm, setShowForm] = useState(true);

  //For the inpage navigation
  const formRef = useRef(null);
  const whyusRef = useRef(null);
  const aboutusRef = useRef(null);
  const ourmissionRef = useRef(null);
  const whatwedoRef = useRef();
  const whatarewesolvingRef = useRef();

  const scrollToForm = (ref) => {
    ref.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  function addSubmitHandler(userInput) {
    fetch(
      "https://v1.nocodeapi.com/unique_usman/google_sheets/iIigOwKFQzsweQTk?tabId=Sheet1",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify([userInput]),
      }
    ).then(() => {
      setShowForm(false);
      setTimeout(() => (window.location.href = window.location.href), 2000);
    });
  }
  return (
    <Router>
      <Navbar
        scrollToForm={scrollToForm}
        formRef={formRef}
        whyusRef={whyusRef}
        aboutusRef={aboutusRef}
        ourmissionRef={ourmissionRef}
        whatwedoRef={whatwedoRef}
        whatarewesolvingRef={whatarewesolvingRef}
      />
      {/* <StyledPage> */}
      <Routes>
        <Route
          path="/"
          element={
            <Body
              scrollToForm={scrollToForm}
              formRef={formRef}
              whyusRef={whyusRef}
              aboutusRef={aboutusRef}
              // faqRef={faqRef}
              ourmissionRef={ourmissionRef}
              whatwedoRef={whatwedoRef}
              whatarewesolvingRef={whatarewesolvingRef}
            />
          }
        />
        ,{/* <Route path="/about" element={<AboutUs />} /> */}
        {/* <Route path="/whyus" element={<WhyUs />} /> */}
      </Routes>
      {/* </StyledPage> */}
      {/* <SimplePaper aboutusRef={aboutusRef}  /> */}
      {/* <WhyUs whyusRef={whyusRef} />
      <Solving />
      <Mission />
      <BasicAccordion faqRef={faqRef} />
      {showForm && <Form formRef={formRef} submitHandler={addSubmitHandler} />}
      {!showForm && <ThankYou />} */}
      <Footer
        scrollToForm={scrollToForm}
        formRef={formRef}
        whyusRef={whyusRef}
        aboutusRef={aboutusRef}
        // faqRef={faqRef}
        ourmissionRef={ourmissionRef}
        whatwedoRef={whatwedoRef}
        whatarewesolvingRef={whatarewesolvingRef}
      />
    </Router>
  );
}

export default App;
